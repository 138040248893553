import React from 'react'
import style from './Footer.module.css'
import { FaInstagram } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { nufolksLogo } from '../../assets';

function Footer() {
    return (
        <div className={style.footer_section}>
            <div className={style.upper_footer}>
                <div className={style.logo}>
                    <img src={nufolksLogo} alt='NuFolks' className={style.footer_logo} />
                </div>
                <div className={`${style.footer_links} ${style.links1}`}>
                    <Link to='/about' className={style.footer_text}>About Us</Link>
                    <Link to='/our-process' className={style.footer_text}>Our Process</Link>
                    <Link to='/services' className={style.footer_text}>Our Services</Link>
                </div>
                <div className={`${style.footer_links} ${style.links2}`}>
                    <Link to='/hire-a-talent' className={style.footer_text}>Hire a Talent</Link>
                    <Link to='/apply-as-a-talent' className={style.footer_text}>Apply as a Talent</Link>
                </div>
                <div className={style.social}>
                    <p>Follow us on</p>
                    <div className={style.social_icons_container}>
                        <a
                            className={`${style.social_icon} ${style.instagram}`}
                            href="https://www.instagram.com/nufolks/"
                            target="_blank"
                            rel="noopener noreferrer">
                            <FaInstagram />
                        </a>
                        <a
                            className={`${style.social_icon} ${style.facebook}`}
                            href="https://www.facebook.com/nufolks/"
                            target="_blank"
                            rel="noopener noreferrer">
                            <FaFacebookF />
                        </a>
                        <a
                            className={`${style.social_icon} ${style.twitter}`}
                            href="https://twitter.com/TheNuFolks"
                            target="_blank"
                            rel="noopener noreferrer">
                            <FaTwitter />
                        </a>
                        <a
                            className={`${style.social_icon} ${style.linkedin}`}
                            href="https://in.linkedin.com/company/nufolks"
                            target="_blank"
                            rel="noopener noreferrer">
                            <FaLinkedinIn />
                        </a>
                    </div>
                </div>
            </div>
            <div className={style.lower_footer}>
                <div className='text-light' style={{ display: 'flex', alignItems: 'center' }}>
                    <p>&copy; 2025 NuFolks | All rights reserved</p>
                </div>
                <div className={style.links}>
                    <Link to='/privacy-policy' className={style.links}>Privacy Policy</Link><br />
                    <Link to='/terms-and-conditions' className={style.links}>Terms & Conditions</Link>
                </div>
            </div>
        </div>
    )
}

export default Footer